
.footer {
  width: 100%;
  margin-top:80px;
  padding-bottom:80px;
  min-height: 260px;
  background-color: #f5f5f5;
  
  div.container {
    /*position:absolute;*/
    padding-top:25px;
    margin-left:20%;
    width:50%;
    
    @media screen and (min-width: @screen-xl-min) {
      margin-left:25%;
      width:40%;
    }
    
    
    div.title {
      text-align:center;
    }
    
    
    .glyphicon {
      padding-right:5px;
      //margin-right:5px;
    }
    

    img {
      margin:5px;
    }
    
    a {
      overflow: hidden; 
      text-overflow: ellipsis; //add "..."
      white-space: nowrap;
    }
    
  }
  
}



