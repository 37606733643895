// VCM Content style
// -------------------------------------------------- 
//BUG!? #include "mixins/vendor-prefixes.less"

.vcmcontent {
  width:100%;
  
/*   background: -moz-linear-gradient(red, yellow, green);  */
  /*background: linear-gradient(red, yellow, green);*/
  
  
  padding-left:4px;
  padding-right:4px;
  
  img {
    background:white;
/*    background-image: url('/res/img/ui/loading/loading_pts.gif');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 25%; 
    background-size: 80px 80px;*/
    border-radius:2px;
  }
  
  
  .badge {
    text-overflow: ellipsis;
    
    &.link {
      background:@brand-primary;
      
      a {
	background:white;
	border-radius: @badge-border-radius;
	padding-left: 5px;
	padding-right: 5px;
      }
    }
  }
   
  .center {
    width:100%;
    text-align:center;
    padding: auto auto;
  }
  
  .justify {
    text-align:justify;
  }
  
  .roundimg {
    img {
      border-radius: 2px;//@thumbnail-border-radius;
    }
  }
  
  
  hr.hiddenSpace {
    border-color:@body-bg;
  }
  
  a.list-group-item {
    cursor:pointer;
  }
  
  

  a[id] {
      cursor:pointer;
  }
  
  a[onclick] {
      cursor:pointer;
  }
  
  
  
  
  
  
  
  //add individual content css [first level]
  

   
  
  
  //news
  &.news {
    
    

    
    article {

      text-align:justify;
    
      @media (min-width: @screen-sm-min ) {

	overflow:visible;
      }
      
      h3 {
	text-align:left;
      }
      
      b {

      }
      
      .img-col {
	img {
	  width:100%;
	  cursor:pointer;
	  border-radius: @border-radius-base;
	  margin-bottom:4px;//2px
	  
	  &.big {
	    min-height:100px;
	  }
	  
	  &.small {
	    width:19%;
	    margin-top:2px;
	  }
	}
      }
      
      iframe {
	width:100%;
      }
    }
  }
  
  
  &.news_horizontal {
    
    
    @media (min-width: @screen-sm-min ) {
      .articleBlock {
	display:block;
	width:100%;
	max-height:620px;
	overflow:auto;//auto
	margin-top:40px;
	margin-bottom:10px;
	/*margin-left:10px;
	margin-left:10px;*/
	
	&.highlight {
	  border-color: @brand-primary; //rgba(255, 0, 0, 0.5);
	  border-radius: @border-radius-base;
	  border-width: 0px;
	  border-style: solid;
	  
	  &.left {
	    border-left-width: 5px;
	    padding-left: 2px;
	  }
	  
	  &.right {
	    border-right-width: 5px;
	    padding-right: 2px;
	  }
	}
	
	/*.scrollOverlay {

	  display:block;
	  width:100%;
	  height:20px;
	  //background:green;
	}*/
      }
      
      .scrollAfter {
	position:relative;
	display:none;
	width:100%;
	height:40px;
/* 	background:green; */
	&.show {
	  display:block;
	}
	
	.btnSpace {
	  position:absolute;
	  display:none;
	  top:0px;
	  width:100px;
	  //display:inline-block;
	  //width:25%;
	  //margin:0px auto;
	  
	  &.left {
	     left:0px;
	     text-align:left;
	  }
	  
	  &.right {
	    right:0px;
	    text-align:right;
	     
	  }
	  
	  a:before {
	    font-size:40px;
	    line-height:10px;
	    content: "...";
	  }
	  
	  &.show {
	    display:block;
	  }
	}
	
      }
      
    }
    
    article {
/*      margin:10px;
      margin-bottom:50px;
      padding-top:50px;*/
      text-align:justify;
    
     @media (min-width: @screen-sm-min ) {
	//-webkit-column-count: 3; // Chrome, Safari, Opera 
	//-moz-column-count: 3; // Firefox 
	//column-count: 3;
      
	/*-webkit-column-width: 150px;
	-moz-column-width: 150px;
	column-width: 150px;

	-webkit-column-gap: 50px;
	-moz-column-gap: 50px;
	column-gap: 50px;*/

	-webkit-column-width: 270px; //270
	-moz-column-width: 270px;
	column-width: 270px;
	
	-webkit-column-gap: 30px;
	-moz-column-gap: 30px;
	column-gap: 30px;
	
	-moz-column-rule: 1px dotted @gray-lighter;
	-webkit-column-rule: 1px dotted @gray-lighter;
	column-rule: 1px dotted @gray-lighter;
      
	max-height:600px;
	
	
/*	padding-bottom:200px;*/
	//max-height:700px;
	//height:inherit;
	//width:inherit;
	//width:800px;
	//overflow:auto;
	
	//width:100%;
	overflow:visible;
      }
      
      h3 {
	text-align:left;
      }
      
      b {
        /*box-sizing: content-box;
	text-align:left;*/
      }
      
      img {
	width:100%;
	cursor:pointer;
	border-radius: @border-radius-base;
	margin-bottom:2px;
	
	&.big {
	  min-height:100px;
	}
	
	&.small {
	  width:19%;
	  margin-top:2px;
	}
      }
      
      iframe {
	width:100%;
      }
    }
  }
  
  
  
  &.welcome {
        
    .news {
      position:relative;
      max-height:354px;
      overflow:hidden;
      border-radius: @border-radius-base;
      
      img {
	position:relative;
	top:0px;
	width:100%;
	min-height:350px;
	@media screen and (max-width: @screen-xs-max) {
	  min-height:250px;
	}
	
	/*max-height:354px;
	overflow:hidden;*/
	/*border-radius: @border-radius-base;*/
      }
      
      
    }
    
    .txtshortBottom {
      margin-bottom:24px;
      min-height:100px;//80px
      text-align:justify;
      
      .txthead {
	width:100%;

	
	h5 {
	  background-color: rgba(255, 255, 255, 0.95);
	  display:inline;
	  
	  
	  .badge {
	    color:white;
	    background:@brand-primary;
	    margin-right:5px;
	    //margin-bottom:2px;
	    border-radius:@border-radius-base;
	    
	  }
	}
      }
    }
    
    
  } //end welcome
  
  
    &.welcome_testing {
        
    .news {
      position:relative;
      max-height:354px;
      overflow:hidden;
      border-radius: @border-radius-base;
      
      img {
	position:relative;
	top:0px;
	width:100%;
	min-height:350px;
	@media screen and (max-width: @screen-xs-max) {
	  min-height:250px;
	}
	
	/*max-height:354px;
	overflow:hidden;*/
	/*border-radius: @border-radius-base;*/
      }
      
      
    }
    
    .txtshortBottom {
      margin-bottom:24px;
      min-height:100px;//80px
      text-align:justify;
      
      .txthead {
	width:100%;

	
	h5 {
	  background-color: rgba(255, 255, 255, 0.95);
	  display:inline;
	  
	  
	  .badge {
	    color:white;
	    background:@brand-primary;
	    margin-right:5px;
	    //margin-bottom:2px;
	    border-radius:@border-radius-base;
	    
	  }
	}
      }
    }
    
    
  } //end welcome_auto
  

  
}




//modals

.modal-vcm {

  &.cloud {
    width:95%; 
    height:95%;
/*    min-height:810px;
    
    @media (min-width: @screen-lg-min ) {
	min-width:1100px;
    }*/
     
    iframe {
      display:block;
      width:100%!important; 
      min-height:800px;
      @media (max-height:940px) {
	min-height:600px;
      }
     }
  }
  
  &.register {
  /*  @media (min-width: @screen-lg-min ) {
      min-width:1120px!important;
    }*/
    
    width:95%;
    
    iframe {
      border-color:white;
      border-width: 0px;
      width:100%!important; 
  
      
      min-height:800px;
      @media (max-height: 1000px ) {
	min-height:700px;
      }
      
      @media (max-height: 800px ) {
	min-height:500px;
      }
      
      @media (max-height: 769px ) {
	min-height:400px;
      }
      
      @media (max-height: 600px ) {
	min-height:300px;
      }
      
      
      //ipad tablet portrait
      
      -ms-transform-origin: top left; // IE 9 
      -webkit-transform-origin: top left; // Chrome, Safari, Opera 
      transform-origin: top left;
      -ms-transform: scale(1.0); 
      -webkit-transform: scale(1.0); 
      transform: scale(1.0);
      
      @media (max-width: @screen-sm-max ) {
	-ms-transform: scale(0.7); 
	-webkit-transform: scale(0.7); 
	transform: scale(0.7);
	width:140%!important; 
      }
      
      @media (min-width: @screen-sm-max) and (max-width: @screen-md-max ) {
	-ms-transform: scale(0.9); 
	-webkit-transform: scale(0.9); 
	transform: scale(0.9);
	width:110%!important; 
      }
      
      
      
/*      min-height:600px;
      @media (min-height: 1000px ) {
	min-height:800px;
      }
      
      -ms-transform-origin: top left; // IE 9 
      -webkit-transform-origin: top left; // Chrome, Safari, Opera 
      transform-origin: top left;
      
      @media (max-width: @screen-sm-max ) {
	min-width:1100px!important;
	-ms-transform: scale(0.5); 
	-webkit-transform: scale(0.5); 
	transform: scale(0.5);
      }

      @media (min-width: @screen-md-min ) {
	min-width:1100px!important;
	-ms-transform: scale(0.75); 
	-webkit-transform: scale(0.75); 
	transform: scale(0.75);
      }
      
      @media (min-width: @screen-lg-min ) {
	min-width:1100px!important;
	-ms-transform: scale(1.0); 
	-webkit-transform: scale(1.0); 
	transform: scale(1.0);
      }*/
      
    }
  }
  
}






//race TODO mv to modal-vcm
.modalRunner {
  table {
    border-collapse:separate;
    border-spacing:4px 8px;
  }
  
  img {
    border-radius: @border-radius-base;
    max-width:((@modal-md) - (50));
  }
}



