
// Inverse navbar VCM

.navbar-vcm {

  //add holala
  @media (max-width: @screen-xs-max ) {
    .navbar-nav .open .dropdown-menu {
  /*      position: static;
	float: none;
	width: auto;
	margin-top: 0px;
	border: 0px none;
	box-shadow: none;*/
	background-color: @brand-primary; //#777777;
    }
  }


  //background-color: rgba(255, 255, 255, 0.9);


  &.top-navbar {
    height:40px; //140
    //background-color: rgba(255, 255, 255, 0.8);
    
    //holala
    //top: 100px;
    top: 14px;
    //min-width: 200px;
    //width: 50%;
    //margin: 0px auto;
    width: 95%;
    margin-right: auto;
    margin-left: 10px;
    border-radius:4px;
    
    .navbar-nav {
      .hideOnBigScreen {
	display:block;
      }
      .showOnBigScreen {
	display:none;
      }
        
      @media (min-width: @screen-sm-min ) {
	.hideOnBigScreen {
	  display:none;
	}
	.showOnBigScreen {
	  display:block;
	}
      }
      
      @media (max-width: @screen-xs-max ) {

      }
    }
  }
  
  &.content-navbar {
    border: 0px;
    @media (max-width: @screen-sm-max ) {
      .navbar-nav > li > a {
	font-size: 13px;
      }
      .navbar-brand {
	font-size: 13px;
      }
    }
  }
  
  &.top-navbar {
  
  
    //add round borders on holala singel menu
    .nav > li > a {
	border-radius: 5px; 
    }
  
  
    @media (max-width: @screen-md-max ) {
      .navbar-nav > li > a {
	font-size: 15px;
	line-height: 24px;
      }
      .navbar-brand {
	font-size: 15px;
      }
      
      .nav > li > a {
	padding: 10px 8px; //10px 15px
	border-radius: 5px; 
      }
      
      
    }
  }
  
  
  .navbar-brand {
    color: @brand-primary;//@navbar-inverse-brand-color;
   /* &:hover,
    &:focus {
      color: @navbar-inverse-brand-hover-color;
      background-color: @navbar-inverse-brand-hover-bg;
    }*/
    
    img {
      position:relative;
      top:-10px;
    }
    @media (max-width: @screen-xs-max ) {
      img {
	width:60%;
      }
    }
  }

  .navbar-text {
    color: @navbar-inverse-color;
  }

  .navbar-nav {
    > li > a {
      /*color: @navbar-inverse-vcm-link-color;*/
      color: @brand-primary;
      //text-shadow: 0px 0px 1px @brand-primary; //@brand-primary
      /*font-size:30px;*/
      font-size:20px;
      
      &:hover,
      &:focus {
        /*color: @navbar-inverse-link-hover-color;
        background-color: @navbar-inverse-link-hover-bg;*/
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: white;
        background-color: @brand-primary;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-inverse-link-disabled-color;
        background-color: white;
      }
    }
    
    .threeDots:before {
      position:relative;
      top:-10px;
      content: "...";
      vertical-align:top;
      font-size: 30px;
      font-weight:bolder;
    }
  }

  // Darken the responsive nav toggle
  .navbar-toggle {
    border-color: @brand-primary;
    &:hover,
    &:focus {
      background-color: white;
    }
    .icon-bar {
      background-color: @brand-primary;
    }
  }

  .navbar-collapse,
  .navbar-form {
    //border-color: white;//darken(@navbar-inverse-bg, 7%);
  }

  // Dropdowns
  .navbar-nav {
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: @brand-primary; //@navbar-inverse-link-active-bg;
        color: white; //@navbar-inverse-link-active-color;
      }
    }
    


    @media (max-width: @grid-float-breakpoint-max) {
      // Dropdowns get custom display
      .open .dropdown-menu {
        > .dropdown-header {
          border-color: @navbar-inverse-border;
        }
        .divider {
          background-color: @navbar-inverse-border;
        }
        > li > a {
          color: white;// @brand-primary;//@navbar-inverse-vcm-link-color;
          &:hover,
          &:focus {
            color: @brand-primary;//@navbar-inverse-link-hover-color;
            background-color: white;// white;//@navbar-inverse-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-inverse-link-active-color;
            //background-color: @navbar-inverse-link-active-bg;
            background-color: @brand-primary;
          }
        }
        
       
        
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-inverse-link-disabled-color;
            background-color: @navbar-inverse-link-disabled-bg;
          }
        }
      }
    }
  }

  .navbar-link {
    color: @navbar-inverse-vcm-link-color;
    &:hover {
      color: @navbar-inverse-link-hover-color;
    }
  }

  .btn-link {
    color: @navbar-inverse-vcm-link-color;
    &:hover,
    &:focus {
      color: @navbar-inverse-link-hover-color;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: @navbar-inverse-link-disabled-color;
      }
    }
  }
  
  @media screen and (min-width: @screen-md-min) {
    .navbar-extra {
      width:100%; //1000, 900
      display:block !important; 
      padding-left:0px;//10, 110 
      padding-top:16px;//15, 20
      
      .elm-std {
	display: inline-block;
	width:24%;
	vertical-align: top;
	margin-right:2px;
	
/* 	float:left;  */
	/*width:260px; */
	/*margin:4px;
	margin-right:12px;//10*/
	color:white; 
	border-right:0px solid white;
	
	height:100%;
	/*margin-top:auto;
	margin-bottom:4px;*/
	

	
	.content {
	  //background: white;
	  background-color: rgba(255, 255, 255, 0.5);
	  &:hover,
	  &:focus {
	    background-color: rgba(255, 255, 255, 1.0);
	    max-height:90px;
	  }
	  min-width:150px;
	  max-width:230px;
	  //min-height:50px;
	  
	  overflow: hidden; 
	  
	  text-overflow: ellipsis;
	  //white-space: pre;
	  
	  max-height:48px;//60px
	  min-height:48px;
	  
	  padding:2px;
	  border-radius:@border-radius-base;
	  
	  color:@text-color;
	  font-size:@font-size-small;
	  
	  cursor:pointer;
	  
	  margin: 0px 0px 0px auto;
	  
	  img {
	    
	  }
	  
	  img.insideTxt {
	    max-height:42px;
	    max-width:50px;
	    margin:2px;
	    margin-right:4px;
	    float:left;
	  }	  
	  
	  	  
	  
	}
	
	.title {
	  width:100%; 
	  text-align:right; 
	  padding-right:0px;
	  font-size:@font-size-small;
	  //text-shadow: 0px 0px 2px @brand-primary;
	  color:@brand-primary; 
	}
	
      }  
    }
    
    .navbar-search {
      display:block !important; 
      margin-top:10px;
      width:100%;//@screen-md-min, 1000px;
/*       background:blue; */
      
      .elm-std {
	max-width:350px;//
	
        margin-left:auto;
	//margin-right:20px;//20,22
	text-align:right;
	
	
	/*background:green;*/
	
	.content {
	  display:inline-block;
	  max-width:150px;//200px
	
	  img {
	    max-height:26px;//25
	    vertical-align: baseline;
	    border-radius:@border-radius-small;
	  }
	  
	  img.inactive {
	   .opacity(0.25);
	  }
	  
	  .popover-title {
	    font-size:@font-size-small;
	  }
	  
	  .popover-content {
	    min-width:200px;
	    font-size:@font-size-small;
	  }
	  
	  span.auth {
	    color:@brand-primary;
	  }
	  
	  
	  //search styles
	  #navSearchSpace {
	    .popover {
	      max-width: none;
	      
	      .popover-content {
		min-width:400px;
	      }
	    }
	  }
	  
	  
	}
      }
    }
    
    .navbar-open-search-extra {
      display:none;
    }
    
  }
  
  @media screen and (max-width: @screen-md-min) {
    &.top-navbar {
      height:auto;
    }
    
    .navbar-extra {
      display:none;
    }
    
    .navbar-search {
      display:none;
    }
    
    
    //@media screen and (min-width: @screen-sm-min) {
      .navbar-open-search-extra {
	//background:blue;
	margin: 10px 5px auto auto;
	display: block;
	width: 30px;
	
	.glyphicon {
	  color:@brand-primary;
	}
      }
    //}
    
    
  }
 
  @media screen and (max-width: @screen-xs-max) {
    .navbar-open-search-extra {
      display:none;
    }
  }
 
}


@media screen and (max-width: @screen-md-min) {
/*  #myModalOpenSearchExtra {
    .navbar-search {
      .elm-std {
	margin-bottom:30px;
	width:100%;
	.content {
	  display:inline-block;
	  max-width:50%;
	  
	  img {
	    max-height:26px;//25
	    vertical-align: baseline;
	    border-radius:@border-radius-small;
	  }
	  
	  img.inactive {
	   .opacity(0.25);
	  }
	  
	  .popover-title {
	    font-size:@font-size-small;
	  }
	  
	  .popover-content {
	    min-width:200px;
	    font-size:@font-size-small;
	  }
	  
	  span.auth {
	    color:@brand-primary;
	  }
	  
	  
	  //search styles
	  #navSearchSpace {
	    .popover {
	      max-width: none;
	      
	      .popover-content {
		min-width:400px;
	      }
	    }
	  }
	}
      }
    }
    .navbar-extra {
      .elm-std {
	  margin-bottom:6px;
	  border-radius:@border-radius-base;
	  border-style: solid;
	  border-width: 1px;
	  padding:4px;
	  border-color:@gray-lighter;
	
	.title {
	  width:100%; 
	  //text-align:right; 
	  //padding-right:0px;
	  font-size:@font-size-h4;
	  color:@brand-primary;
	}
	.content {
	  border-radius:@border-radius-base;
	  
	  div {
	   min-height:50px;
	   width:100%; 
	  }
	  
	  &:hover,
	  &:focus {
	    background-color: @gray-lighter;
	  }
	
	
	  min-height:50px;
	  color:@text-color;
	  font-size:@font-size-small; 
	  cursor:pointer;
	  
	  img.insideTxt {
	    max-height:42px;
	    max-width:50px;
	    margin:2px;
	    margin-right:4px;
	    float:left;
	  }
	}
      }
    }
  }*/
}



@media screen and (min-width: @screen-sm-min) {

/*  nav.navbar.top-navbar.shrink  {
    height: 40px;
    min-height: 40px;
  }


  
  nav.shrink.top-navbar {
    .navbar-nav {
      a.dropdown-toggle, a.dropdown-toggle-no {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	//font-size: 15px;
      }
    }
  }

  nav.shrink.top-navbar a img {
    width:85%;
  }

  nav.shrink.top-navbar .navbar-brand {
     //font-size: 25px; 
  }


  nav.shrink.top-navbar .navbar-extra {
    display:none !important;
  }
  
  nav.shrink.top-navbar .navbar-search {
    margin-top:5px;
    img {
      max-height:26px;//25
      width:auto;
    }
  }
  
  nav.shrink.top-navbar .navbar-open-search-extra {
    margin-top:2px;
  }
 */

}


@media screen and (min-width: @screen-md-min) {

}

