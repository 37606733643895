
.widget-countdown {
  font-size:40px; 
  color: @brand-primary;
  text-shadow: 0px 0px 2px white;
  
  -webkit-animation: widget-alpha-animation 2s;
  animation: widget-alpha-animation 2s;
}

.widget-countdown-black-white {
  font-size:40px; 
  color: white;
  text-shadow: 0px 0px 2px black;
}

.widget-headline {
  border-left-width:10px;
  border-radius: @border-radius-base;
  min-width:320px;
  max-width:350px;
  overflow:visible;

  /*background: fade(@navbar-inverse-vcm-bg, 75%);*/
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-animation: widget-alpha-animation 2s; 
  animation: widget-alpha-animation 2s;
  
  h1 {
    font-size: floor((@font-size-base * 3.6));
  }
  
  .widget-headline-countdown {
    font-size:30px; 
    color: @brand-primary;
    //text-shadow: 0px 0px 2px white;
  }
  
  footer,div {
    color:@text-color;
    cite {
      font-style:italic;
      font-weight:bold;
    }
  }
  
}

/*.headline_crazy {
  img {
    @media screen and (max-width: @screen-lg-min) {
      width:(@screen-lg-min - 360);
    }
    @media screen and (max-width: @screen-md-min) {
      width:(@screen-md-min - 260);
    }
    @media screen and (max-width: @screen-sm-min) {
      width:(@screen-sm-min - 200);
    }
    @media screen and (max-width: @screen-xs-min) {
      width:(@screen-xs-min - 140);
    }
  }
}*/


.headline_toe {

  .widget-countdown-black-white {
    margin-left:38px;
  }

  @media screen and (max-width: @screen-xs-max) {
    img {
      width:300px;
    }
    .widget-countdown-black-white {
      margin-left:14px;
    }
  }
}


#myModalSponsorstatement {
  .modal-body {
    .list-group-item-heading {
      span {
	max-width:300px;
	overflow: hidden; 	  
	text-overflow: ellipsis;
	white-space: nowrap;
      }
      &:hover,
      &:focus {
	span {
	  max-width:500px;
	}
      }
    }
    .list-group-item-text {
      .clearfix();
      img {
	float:left;
	max-width:40px;
	border-radius:@border-radius-base;
	margin-right:@border-radius-base;
      }
    }
  }
}
