//
// Carousel VCM
// --------------------------------------------------

.carousel-menu {
  position: absolute;
  top: (ceil((1080px * 0.5)) - (100)); 
  width: 90%;
  left:5%;
  
  @media (max-width: @screen-sm-max ) {
    width: 100%;
    left: 0%;  
    .content-navbar-block {
      width: 100%;
    }
  }
  
  @media (max-width: @screen-md-max ) {
    .content-navbar-block {
      width: 95%;
    }
  }
  
/*  width:90%;
  margin:0px auto;*/
  z-index: 26;
}

@media screen and (max-width: @screen-sm-min) {
  .carousel-menu {
    top: (ceil((1080px * 0.35)) - (100)); 
  }
}

//new
@media screen and (min-width: 1280px) and (min-height: 700px) { 
  .carousel-menu {
    top: (ceil((1080px * 0.6)) - (100)); 
  }
}


@media screen and (min-width: 1560px) and (min-height: 900px) { //min-width:1900px
  .carousel-menu {
    top: (ceil((1080px * 0.7)) - (100)); 
  }
}

//new
@media screen and (min-width: 1900px) and (min-height: 1000px) { 
  .carousel-menu {
    top: (ceil((1080px * 0.8)) - (100)); 
  }
}


.carousel-content {
/*  position: absolute;
  top: 300px;// ceil((-1080px * 0.25));*/
/*  width: 90%;
  left:5%;*/

  width:100%;
  
  background: linear-gradient(@body-bg, lighten(@body-bg, 5%), @body-bg);
  
/*   background-color: @body-bg; */
  
  
/*   background-color: linear-gradient(red, yellow, green); */
  //CSS 3.0
/*  background-image:url(/res/img/ui/img_content_border_top.png), 
  url(/res/img/ui/img_content_border_bottom.png), 
  url(/res/img/ui/img_content_border_left.png),
  url(/res/img/ui/img_content_border_right.png);
  background-position:top, bottom, left, right;
  background-repeat:repeat-x, repeat-x, repeat-y, repeat-y;*/
  
  
/*  border-top-width: 1px;
  border-top-style:solid;
  border-top-color: @navbar-inverse-border;
  
  border-bottom-width: 1px;
  border-bottom-style:solid;
  border-bottom-color: @navbar-inverse-border;*/
  
  .container {
    //background-color: @body-bg;
    //border-radius: 4px;
    
    min-height:600px;
    padding-top:10px;
    padding-bottom:20px;
  }
  
}


.carousel-img {
 position: relative;
/* top: ceil((1080px * -0.125));*/
 width:120%; //100% TODO: start with 150%??
 left:-10%;
 min-height: ceil((1080px * 0.25));
 min-width: ceil((1920px * 0.25));
 background:white;//#dddddd
 
  background-image: url('/res/img/ui/loading/loading_pts.gif');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 25%; 
  background-size: 100px 100px;
 
  img {
    position:relative;
    z-index:3; //2
    width:100%;
    
    &.more {
      position:absolute;
      top:0px;
      left:0px;
      z-index:2; //3
      opacity:1;
    }
    
    &.ani {
      /*z-index:3; */ //MSIE
      //http://www.w3schools.com/css/css3_animations.asp
    
     /* -webkit-animation-name: cf4FadeInOut;
      -webkit-animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
    
    
      animation-name: cf4FadeInOut;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      
      -webkit-animation-duration: 16s;
      -moz-animation-duration: 16s;
      -o-animation-duration: 16s;
      animation-duration: 16s;
      
      &:nth-of-type(1) {
	-webkit-animation-delay: 0s;
	-moz-animation-delay: 0s;
	-o-animation-delay: 0s;
	animation-delay: 0s;
      }
      &:nth-of-type(2) {
	-webkit-animation-delay: 8s;
	-moz-animation-delay: 8s;
	-o-animation-delay: 8s;
	animation-delay: 8s;
      }
      &:nth-of-type(3) {
	-webkit-animation-delay: 16s;
	-moz-animation-delay: 16s;
	-o-animation-delay: 16s;
	animation-delay: 16s;
      }
      &:nth-of-type(4) {
	-webkit-animation-delay: 24s;
	-moz-animation-delay: 24s;
	-o-animation-delay: 24s;
	animation-delay: 24s;
      }
      
      @media screen and (max-width: @screen-sm) {
	-webkit-animation-play-state:paused;
	animation-play-state:paused;
      }
      @media screen and (min-width: @screen-sm) {
	-webkit-animation-play-state:running;
	animation-play-state:running;
      }*/
      
    }
    
    /*@keyframes cf4FadeInOut {
      0% {
	opacity:1;
      }
      
      30% {
	opacity:1;
      }
      
      35% {
	opacity:0;
      }

      65% {
	opacity:0;
      }
      
      75% {
	opacity:1;
      }
    }
    
    @-webkit-keyframes cf4FadeInOut {
      0% {
	opacity:1;
      }
      
      30% {
	opacity:1;
      }
      
      35% {
	opacity:0;
      }

      65% {
	opacity:0;
      }
      
      75% {
	opacity:1;
      }
    }*/
    
    
    
  }
}





@media screen and (max-width: @screen-md-min) {
  .carousel-img {
    position: relative;
    width:200%;
    left:-50%;
  } 
  
}

.carousel-img-border {
   position: relative;
   height: ceil((1080px * 0.5));
  /*height: 50%;*/
   overflow:hidden;
  /*vertical-align: middle;*/
  
   &.startpage {
    height: ceil((1080px * 0.75));
  }
  
  
  .carousel-img-overlay {
        /*    position: relative;
        top: ceil((1080px * -1.5));
        height: ceil((1080px * 2));
        overflow:hidden;*/
    position: absolute;
    z-index:20;
    top: 0px;
    width:100%;
    height:100%;
    
        //background-image:url(/res/img/ui/img_overlay.png), 
        //http://www.transparenttextures.com/ based on http://subtlepatterns.com/ uses CC BY-SA 3.0
   /*background-image:url(/res/img/ui/overlay_textures/gplay.pn),//gplay.png,light-honeycomb-dark.png,strange-bullseyes.png,(white-diamond.png,worn-dots.png) 
    url(/res/img/ui/img_content_border_left.png),
    url(/res/img/ui/img_content_border_right.png);
    background-position:top, left, right;
    background-repeat:repeat, repeat-y, repeat-y;  */ 
    
    background-image:
    url(/res/img/ui/img_content_border_left.png),
    url(/res/img/ui/img_content_border_right.png);
    background-position:left, right;
    background-repeat:repeat-y, repeat-y;  
    
  }
 
   .carousel-img-widget {
    position: absolute;
    z-index:21;
    top:2%;//20% 10% 
    width:100%;
    height:95%;
    
      .container {
	height: 33.3%;
	//width: 90%;
      
	&.std {
	  width: 90%;
	}
      
	&.aligned {
	  //width:auto;
	}
      
	.row {
	  height: 100%;
	}
      
	.col-xs-4,.col-lg-4  {
	  height:100%;
	  padding-top:5%; 
	  padding-bottom:auto;
	  vertical-align:center;
	  overflow:visible;
/* 	  pointer-events: none; */
		  
	  .widget-std-container {
	    pointer-events: auto;
	    max-width:270px;
	    min-width:240px;
	    text-align:left;
	    padding:5px;
	    margin: 4px auto;
	    background: fade(@navbar-inverse-vcm-bg, 75%);
	    border-radius: 4px;
	    
	    -webkit-animation: widget-alpha-animation 2s; /* Chrome, Safari, Opera */
	    animation: widget-alpha-animation 2s;
	    
	  }
	  
	  .widget-std-container-bg-only {
	    padding:5px;
	    //margin: 4px auto;
	    background: fade(@navbar-inverse-vcm-bg, 75%);
	    border-radius: 4px;
	  }
	  
	  
	  /* Chrome, Safari, Opera */
	  @-webkit-keyframes widget-alpha-animation {
	      from { .opacity(0) }
	      to { .opacity(0.75) }
	  }

	  /* Standard syntax */
	  @keyframes widget-alpha-animation {
	      from { .opacity(0) }
	      to { .opacity(0.75) }
	  }
	  
	}
      }
   }
}

@media screen and (max-width: @screen-sm-min) {
  .carousel-img-border {
    height: ceil((1080px * 0.35));   
    &.startpage {
      height: ceil((1080px * 0.5));
      .carousel-img {
	width:300%;
	left:-100%;
      }
    }
  }
}

@media screen and (max-width: @screen-lg-min) and (min-width: @screen-sm-min) {
  .carousel-img-border {  
    &.startpage {
      .carousel-img {
	width:200%;
	left:-50%;
      }
    }   
  }
}

@media screen and (max-width: 1500px) and (min-width: @screen-lg-min) {
  .carousel-img-border {  
    &.startpage {
      .carousel-img {
	width:150%;
	left:-25%;
      }
    }   
  }
}



//new
@media screen and (min-width: 1280px) and (min-height: 700px) { 
  .carousel-img-border {
    height: ceil((1080px * 0.6));
  }
}


/*@media screen and (min-width: 1900px) {*/
/*@media screen and (min-height: 1000px) and (min-device-pixel-ratio: 1.4) {*/
@media screen and (min-width: 1560px) and (min-height: 900px) { //min-width: 1900px
  .carousel-img-border {
    height: ceil((1080px * 0.7));
  }
}

//new
@media screen and (min-width: 1900px) and (min-height: 1000px) { 
  .carousel-img-border {
    height: ceil((1080px * 0.8));
  }
}



//indicators

/*@media screen and (max-width: @screen-sm-min) {
  .carousel-indicators {
    top: (ceil((1080px * 0.35)) - (30)); 
  }
}

@media screen and (min-width: 1280px) and (min-height: 700px) { 
  .carousel-indicators {
    top: (ceil((1080px * 0.6)) - (30)); 
  }
}

@media screen and (min-width: 1560px) and (min-height: 900px) {
  .carousel-indicators {
    top: (ceil((1080px * 0.8)) - (30)); 
  }
}

@media screen and (min-width: 1900px) and (min-height: 1000px) { 
  .carousel-indicators {
    top: (ceil((1080px * 0.9)) - (30)); 
  }
}*/






/*.carousel-img-overlay {
  position: relative;
  top: ceil((1080px * -1.5));
  height: ceil((1080px * 2));
  overflow:hidden;
  background-image:url(/res/img/ui/img_overlay.png), 
  url(/res/img/ui/img_content_border_left.png),
  url(/res/img/ui/img_content_border_right.png);
  background-position:top, left, right;
  background-repeat:repeat, repeat-y, repeat-y;
  
  
}*/


