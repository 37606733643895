// VCM Content style
// -------------------------------------------------- 


.vcmbanner {
  min-width:160px;
  height:624px;
  margin-top:30px;
  padding: @thumbnail-padding;
  padding-top: 12px;
  padding-bottom: 12px;
  background: @thumbnail-bg;
  border: 1px solid @thumbnail-border;
  border-radius: @thumbnail-border-radius;
  .transition(all .2s ease-in-out);
}

